import React, { useState, useEffect, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "tailwindcss/tailwind.css";
import "react-phone-input-2/lib/style.css";
import PhoneInput from "react-phone-input-2";
import "react-datepicker/dist/react-datepicker.css";
import styled from "styled-components";
import { useMakeBookingMutation } from "../pages/booking/apiSlice";
import LoadingAnimation from "./LoadingAnimation";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const ValidationError = styled.span`
  display: block;
  margin-top: 0.25rem;
  color: red;
  font-size: 0.875rem;
`;

const LockZoomContainer = styled.div`
  transform: scale(1);
  transform-origin: 0 0;
  max-width: 100vw;
  padding: 10px;
  overflow: hidden;
`;

const RoomDetails = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const [rooms, setRooms] = useState(JSON.parse(localStorage.getItem("rooms")));
  const { room, tempId, checkIn, checkOut, guestType, adults, children } =
    state || {};
  const [formDataf, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    requirement: "",
    numOfRoomsBooked: 1,
    cidNumber: "",
  });
  const [idCopyFiles, setIdCopyFiles] = useState([]);
  const [priceList, setPriceList] = useState([]);
  const [formErrors, setFormErrors] = useState({
    name: "",
    email: "",
    phone: "",
    idCopy: "",
    cidNumber: "",
  });
  const [paymentOption, setPaymentType] = useState("full");
  const [submitted, setSubmitted] = useState(false);
  const fileInputRef = useRef(null);
  const [makeBooking, { data, isLoading, isError, error }] =
    useMakeBookingMutation();
  const [roomId, setRoomId] = useState("");
  const additionalPrices = {
    breakfast: 350,
    lunch: 650,
    dinner: 750,
    "tea snacks": 150,
    "conference hall": 15000,
    "extra mattress": 1000,
  };

  useEffect(() => {
    if (room && room.selectedPlan) {
      handlePlanChange(room.selectedPlan);
    }
    setRoomId(room._id);
  }, [room, checkIn, checkOut]);

  useEffect(() => {
    const services = formDataf.requirement
      .toLowerCase()
      .split(",")
      .map((item) => item.trim())
      .filter((item) => additionalPrices[item]);

    setPriceList((prevList) => {
      const baseItems = prevList.filter(
        (item) =>
          !Object.keys(additionalPrices).includes(item.item.toLowerCase())
      );

      const updatedList = [...baseItems];
      services.forEach((service) => {
        const existingIndex = updatedList.findIndex(
          (item) => item.item.toLowerCase() === service
        );
        if (existingIndex !== -1) {
          updatedList[existingIndex] = {
            item: service,
            price: additionalPrices[service],
          };
        } else {
          updatedList.push({ item: service, price: additionalPrices[service] });
        }
      });

      return updatedList;
    });
  }, [formDataf.requirement]);

  const calculateNights = (checkInDate, checkOutDate) => {
    const checkIn = new Date(checkInDate);
    const checkOut = new Date(checkOutDate);
    const timeDiff = Math.abs(checkOut - checkIn);
    const nights = Math.ceil(timeDiff / (1000 * 60 * 60 * 24));
    return nights;
  };

  const validateField = (id, value) => {
    if (!value && id !== "requirement") {
      return "This field is required";
    }

    switch (id) {
      case "name":
        return !/^[a-zA-Z ]+$/.test(value)
          ? "Name should contain only letters and spaces"
          : "";
      case "email":
        return !/\S+@\S+\.\S+/.test(value) ? "Invalid email address" : "";
      case "phone":
        const digitsOnly = value.replace(/\D/g, "");
        return digitsOnly.length < 7 || digitsOnly.length > 15
          ? "Invalid phone number"
          : "";
      case "idCopy":
        return validateIdCopy(value);
      case "cidNumber":
        return !/^\d{11}$/.test(value)
          ? "CID number must be exactly 11 digits"
          : "";
      default:
        return "";
    }
  };

  const validateIdCopy = (files) => {
    if (guestType === "local") {
      if (files.length === 0) {
        return "ID Copy is required for local guests";
      }
      if (files.length !== 2) {
        return "Please upload both front and back images of your ID";
      }
      if (!files.every((file) => file.type.startsWith("image/"))) {
        return "Please upload only image files";
      }
    }
    return "";
  };

  const handleChange = (e) => {
    const { id, value } = e.target;
    let processedValue = value;

    if (id === "cidNumber") {
      processedValue = value.replace(/\D/g, "").slice(0, 11);
    }

    const error = validateField(id, processedValue);

    setFormErrors((prevErrors) => ({ ...prevErrors, [id]: error }));
    setFormData((prevData) => ({ ...prevData, [id]: processedValue }));
  };

  const handleFileChange = (e) => {
    const newFiles = Array.from(e.target.files);
    setIdCopyFiles((prevFiles) => {
      const updatedFiles = [...prevFiles, ...newFiles].slice(0, 2); // Keep only the first two files
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        idCopy: validateIdCopy(updatedFiles),
      }));
      return updatedFiles;
    });
  };

  const handleRemoveFile = (index) => {
    setIdCopyFiles((prevFiles) => {
      const updatedFiles = prevFiles.filter((_, i) => i !== index);
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        idCopy: validateIdCopy(updatedFiles),
      }));
      return updatedFiles;
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    console.log("submitting");
    // Validate all fields
    const errors = {
      ...Object.keys(formDataf).reduce((acc, key) => {
        // Skip CID number validation for regional guests
        if (
          guestType === "foreigner" &&
          (key === "cidNumber" || key === "idCopy")
        ) {
          return acc;
        }
        acc[key] = validateField(key, formDataf[key]);
        return acc;
      }, {}),
    };

    // Only validate ID copy for local guests
    if (guestType === "local") {
      errors.idCopy = validateIdCopy(idCopyFiles);
    }

    const additionalServices = formDataf.requirement
      .toUpperCase()
      .split(",")
      .map((item) => item.trim())
      .filter((item) => additionalPrices[item]);

    const additionalCost = additionalServices.reduce(
      (total, service) => total + additionalPrices[service],
      0
    );
    // const deleteIds = [];
    const deleteIds = rooms.map((r) => {
      return r.tempId;
    });
    console.log(deleteIds);
    const totalAmountFromFrontend = calculateTotalPrice(0);
    const plan = room.selectedPlan;
    const submissionData = {
      ...formDataf,
      idCopy: idCopyFiles,
      roomId,
      paymentOption,
      plan,
      tempId,
      deleteIds,
      checkIn,
      checkOut,
      guestType,
      adults,
      children,
      totalAmountFromFrontend,
      additionalServices,
    };
    const formData = new FormData();
    Object.entries(submissionData).forEach(([key, value]) => {
      if (key === "idCopy") {
        value.forEach((file) => {
          formData.append("images", file);
        });
      } else {
        formData.append(key, value);
      }
    });

    // Here you would typically send the data to your server or perform further actions
    try {
      console.log("Sending booking request");
      const result = await makeBooking(formData).unwrap();
      if (error) {
        toast.error("An error occurred while booking. Please try again", {
          position: "bottom-left",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      } else {
        console.log("congrates", result);
        toast.success("Successfully Booked", {
          position: "bottom-left",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        setTimeout(() => {
          console.log("hhh");
          navigate("/proceedToPayment", {
            state: { requestData: result.data },
          });

          const form = document.getElementById("bfsForm");
          const data = result.data;
          for (let key in data) {
            if (form) {
              // alert(key + ": " + data[key]);
              form.elements[key].value = data[key];
            }
          }
          form.submit();
          // navigate("/checkout"); // Redirect after 5 seconds
        }, 3000);
      }
    } catch (err) {
      console.log(err);
      toast.error("An error occurred while booking. Please try again", {
        position: "bottom-left",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      setTimeout(() => {
        // navigate("/booking"); // Redirect after 5 seconds
      }, 3000);
    }
  };

  const calculateTotalPrice = (additionalCost) => {
    const subTotal = priceList.reduce(
      (total, listItem) => total + listItem.price,
      0
    );
    const checkInD = new Date(checkIn);
    const checkOutD = new Date(checkOut);
    const timeDifference = checkOutD - checkInD;
    const dayDifference = timeDifference / (1000 * 3600 * 24);
    const total = subTotal * formDataf.numOfRoomsBooked + additionalCost;
    const taxAndServiceCharge = total * 0.2;
    const totalAmountFromFrontend = total + taxAndServiceCharge;
    return paymentOption === "half"
      ? totalAmountFromFrontend / 2
      : totalAmountFromFrontend;
  };

  const planNotices = {
    ep: "Only Room",
    cp: "Room + Breakfast",
    map: "Room + Breakfast + any one meal",
    ap: "Room + three meals",
  };

  const getPlanNotice = (plan) => planNotices[plan] || "";

  const handlePlanChange = (plan) => {
    const nights = calculateNights(checkIn, checkOut);
    const roomPrice = room[plan][guestType] * nights;

    const newPriceList = [
      {
        item: `${room.roomType.charAt(0).toUpperCase()}${room.roomType.slice(
          1
        )} (${plan.toUpperCase()} Plan) - ${nights} Nights`,
        price: roomPrice,
      },
      {
        item: getPlanNotice(plan),
        price: 0,
      },
    ];

    setPriceList(newPriceList);
  };

  return (
    <LockZoomContainer className="mt-4 px-4 lg:px-4">
      <div className="container mx-auto mt-20 mb-8 flex flex-col md:flex-row items-center justify-between container mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-3xl font-garamond  text-center md:text-left mb-4 md:mb-0">
          {room.roomType.toUpperCase()} ROOM
        </div>
        <div className="w-full md:max-w-5xl border-t-4 border-[#c19e68] p-6 bg-[#1e1e1e] text-white">
          {checkIn && checkOut && guestType && (
            <form
              className="flex flex-wrap items-center justify-between w-full space-x-0 md:space-x-4 px-5"
              readOnly
            >
              <div className="grid grid-cols-2 gap-4 w-full md:flex md:flex-row md:w-auto md:space-x-4">
                <div className="flex flex-col items-start relative w-full md:w-auto mb-4 md:mb-0">
                  <label
                    htmlFor="checkin"
                    className="text-bg font-montserrat text-[#a3a29b]"
                  >
                    Check In
                  </label>
                  <div className="relative w-full">
                    <input
                      type="date"
                      id="checkin"
                      value={checkIn}
                      readOnly
                      className="focus:outline-none w-full uppercase text-bg font-garamond text-white bg-[#1e1e1e] appearance-none"
                      style={{ border: "none" }}
                    />
                  </div>
                </div>
                <div className="border-r border-gray-600 h-10 hidden md:block"></div>
                <div className="flex flex-col items-start relative w-full md:w-auto mb-4 md:mb-0">
                  <label
                    htmlFor="checkout"
                    className="text-bg font-montserrat text-[#a3a29b]"
                  >
                    Check Out
                  </label>
                  <div className="relative w-full">
                    <input
                      type="date"
                      id="checkout"
                      value={checkOut}
                      readOnly
                      className="focus:outline-none w-full uppercase text-bg font-garamond text-white bg-[#1e1e1e] appearance-none"
                      style={{ border: "none" }}
                    />
                  </div>
                </div>
                <div className="border-r border-gray-600 h-10 hidden md:block"></div>
                <div className="flex flex-col items-start relative w-full md:w-auto mb-4 md:mb-0">
                  <label
                    htmlFor="guestType"
                    className="text-bg font-montserrat text-[#a3a29b]"
                  >
                    Guest Type
                  </label>
                  <div className="relative w-full">
                    <select
                      id="guestType"
                      value={guestType}
                      disabled
                      className="focus:outline-none w-full uppercase text-bg font-garamond text-white bg-[#1e1e1e] appearance-none disabled:opacity-100 disabled:text-white"
                      style={{ border: "none" }}
                    >
                      <option value="local">Local Guest</option>
                      <option value="foreigner">Regional Guest</option>
                    </select>
                  </div>
                </div>
                <div className="border-r border-gray-600 h-10 hidden md:block"></div>
                <div className="flex flex-col items-start relative w-full md:w-auto mb-4 md:mb-0">
                  <label
                    htmlFor="children"
                    className="text-bg text-[#a3a29b] font-montserrat"
                  >
                    Children
                  </label>
                  <div className="relative w-full">
                    <select
                      id="children"
                      value={children}
                      disabled
                      className="focus:outline-none w-full uppercase text-bg font-garamond text-white bg-[#1e1e1e] md:text-left appearance-none disabled:opacity-100 disabled:text-white"
                      style={{ border: "none" }}
                    >
                      {[...Array(10).keys()].map((num) => (
                        <option key={num} value={num}>
                          {num}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="border-r border-gray-600 h-10 hidden md:block"></div>
                <div className="flex flex-col items-start relative w-full md:w-auto mb-4 md:mb-0">
                  <label
                    htmlFor="adults"
                    className="text-bg text-[#a3a29b] font-montserrat"
                  >
                    Adults
                  </label>
                  <div className="relative w-full">
                    <select
                      id="adults"
                      value={adults}
                      disabled
                      className="focus:outline-none w-full uppercase text-bg font-garamond text-white bg-[#1e1e1e] md:text-left appearance-none disabled:opacity-100 disabled:text-white"
                      style={{ border: "none" }}
                    >
                      {[...Array(10).keys()].map((num) => (
                        <option key={num + 1} value={num + 1}>
                          {num + 1}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="border-r border-gray-600 h-10 hidden md:block"></div>
                <div className="flex flex-col items-center justify-center w-full md:w-auto mb-4 md:mb-0">
                  <button
                    className="bg-[#c19e68] text-white px-6 py-2 text-bg font-garamond w-full md:w-auto"
                    onClick={() => navigate("/booking")}
                  >
                    Back
                  </button>
                </div>
              </div>
            </form>
          )}
        </div>
      </div>
      <div className="container mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex flex-wrap mt-3">
          <div className="w-full lg:w-2/3">
            <img
              src={room.image}
              className="w-full h-auto mb-4"
              alt={room.roomType}
            />
            <div className="mt-8">
              <div className="flex flex-wrap -mx-4">
                <div className="w-full md:w-1/2 px-4 mb-8 md:mb-0">
                  <h6 className="text-xl font-bold font-garamond  mb-4">
                    TERMS & CONDITIONS
                  </h6>
                  <ul className="list-disc list-inside font-montserrat space-y-2">
                    <li>Quoted rates are all in Bhutanese Ngultrum.</li>
                    <li>
                      The rate is exclusive of 10% Bhutan Sales Tax & 10%
                      Service Charge.
                    </li>
                  </ul>
                  <h6 className="text-xl font-bold font-garamond mt-8 mb-4">
                    HOTEL AMENITIES
                  </h6>
                  <ul className="list-disc list-inside font-montserrat space-y-2">
                    <li>Multi Cuisine Restaurant.</li>
                    <li>Bar.</li>
                    <li>24/7 Front Desk.</li>
                    <li>Option for Deluxe & Standard Room.</li>
                    <li>Conference Hall.</li>
                    <li>Free Private Parking.</li>
                    <li>Stage for live program.</li>
                    <li>Swimming Pool.</li>
                    <li>
                      Glass house to organize birthday and private parties.
                    </li>
                    <li>
                      Pool side green lawn to host marriage party and other
                      events.
                    </li>
                  </ul>
                </div>

                <div className="w-full md:w-1/2 px-4">
                  <h6 className="text-xl font-bold font-garamond mb-4">
                    PAYMENT CONDITIONS
                  </h6>
                  <ul className="list-disc list-inside font-montserrat space-y-2">
                    <li>
                      50% Payment required at the time of booking confirmation.
                    </li>
                    <li>Balance payment shall be made during check-in.</li>
                  </ul>
                  <h6 className="text-xl font-bold font-garamond mt-8 mb-4">
                    ROOM AMENITIES
                  </h6>
                  <ul className="list-disc list-inside font-montserrat space-y-2">
                    <li>Free Wi-Fi.</li>
                    <li>Cable TV.</li>
                    <li>Complementary Tea & Coffee.</li>
                    <li>Water Kettle.</li>
                    <li>Complementary 2 Bottles of Water.</li>
                    <li>Essentials (Towels, Soap).</li>
                    <li>Balcony.</li>
                    <li>Laundry Services.</li>
                    <li>Air Condition in all rooms.</li>
                    <li>Mini Freezer.</li>
                    <li>Hair Dryer.</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <div className="w-full lg:w-1/12 flex justify-center">
            <div className="border-r-2"></div>
          </div>
          <div className="w-full lg:w-1/4 mt-8 lg:mt-0">
            <div>
              <h6 className="text-xl font-bold font-garamond mb-4">
                ADDITIONAL SERVICES
              </h6>

              <div className="overflow-x-auto mb-8">
                <table className="w-full text-left table-auto font-montserrat">
                  <thead>
                    <tr>
                      <th className="px-2 py-1 border">Particulars</th>
                      <th className="px-2 py-1 border">Rates</th>
                    </tr>
                  </thead>
                  <tbody>
                    {[
                      { item: "Breakfast", price: 350 },
                      { item: "Lunch", price: 650 },
                      { item: "Dinner", price: 750 },
                      { item: "Tea Snacks", price: 150 },
                      { item: "Conference Hall", price: 15000 },
                      { item: "Extra mattress", price: 1000 },
                    ].map(({ item, price }) => (
                      <tr key={item}>
                        <td className="px-2 py-1 border">{item}</td>
                        <td className="px-2 py-1 border">BTN {price}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>

              <form onSubmit={handleSubmit}>
                <div className="mb-4">
                  <label
                    htmlFor="name"
                    className="block mb-2 text-xl font-bold font-garamond"
                  >
                    Name
                  </label>
                  <input
                    id="name"
                    className={`w-full px-4 py-2 border font-montserrat ${
                      formErrors.name ? "border-red-500" : ""
                    }`}
                    type="text"
                    placeholder="Enter your name"
                    value={formDataf.name}
                    onChange={handleChange}
                  />
                  {formErrors.name && (
                    <ValidationError>{formErrors.name}</ValidationError>
                  )}
                </div>

                <div className="mb-4">
                  <label
                    htmlFor="email"
                    className="block mb-2 text-xl font-bold font-garamond"
                  >
                    Email address
                  </label>
                  <input
                    id="email"
                    type="email"
                    className={`w-full px-4 py-2 border font-montserrat ${
                      formErrors.email ? "border-red-500" : ""
                    }`}
                    placeholder="example@gmail.com"
                    value={formDataf.email}
                    onChange={handleChange}
                  />
                  {formErrors.email && (
                    <ValidationError>{formErrors.email}</ValidationError>
                  )}
                </div>

                <div className="mb-5">
                  <label
                    htmlFor="phone"
                    className="block mb-2 text-xl font-bold font-garamond"
                  >
                    Phone Number
                  </label>
                  <PhoneInput
                    country={"bt"}
                    value={formDataf.phone}
                    onChange={(phone) => {
                      setFormData((prevData) => ({ ...prevData, phone }));
                      setFormErrors((prevErrors) => ({
                        ...prevErrors,
                        phone: validateField("phone", phone),
                      }));
                    }}
                    inputProps={{
                      name: "phone",
                      required: true,
                      className: `w-full px-10 py-2 border font-montserrat ${
                        formErrors.phone ? "border-red-500" : ""
                      }`,
                    }}
                  />
                  {formErrors.phone && (
                    <ValidationError>{formErrors.phone}</ValidationError>
                  )}
                </div>

                <div className="mb-4">
                  <label
                    htmlFor="requirement"
                    className="block mb-2 text-xl font-bold font-garamond"
                  >
                    Special Requirements
                  </label>
                  <textarea
                    id="requirement"
                    className="w-full px-4 py-2 border font-montserrat"
                    rows="4"
                    placeholder="Enter your requirements"
                    value={formDataf.requirement}
                    onChange={handleChange}
                  ></textarea>
                </div>

                <div className="mb-4">
                  <label
                    htmlFor="numOfRoomsBooked"
                    className="block mb-2 text-xl font-bold font-garamond"
                  >
                    Number of Rooms
                  </label>
                  <select
                    id="numOfRoomsBooked"
                    className="w-full px-4 py-2 border font-montserrat"
                    value={formDataf.numOfRoomsBooked}
                    onChange={handleChange}
                  >
                    {[1, 2, 3, 4].map((num) => (
                      <option key={num} value={num}>
                        {num} {num === 1 ? "Room" : "Rooms"}
                      </option>
                    ))}
                  </select>
                </div>
                {guestType === "local" && (
                  <div className="mb-4">
                    <label
                      htmlFor="idCopy"
                      className="block mb-2 text-xl font-bold font-garamond"
                    >
                      Upload ID Copy (Front and Back):
                    </label>
                    <input
                      type="file"
                      id="idCopy"
                      multiple
                      ref={fileInputRef}
                      accept="image/*"
                      onChange={handleFileChange}
                      className="hidden"
                    />
                    <button
                      type="button"
                      onClick={() => fileInputRef.current.click()}
                      className={`w-full py-2 bg-white text-black border focus:outline-none font-montserrat ${
                        formErrors.idCopy ? "border-red-500" : "border-gray-300"
                      }`}
                    >
                      Select Files
                    </button>
                    <div className="mt-2">
                      {idCopyFiles.map((file, index) => (
                        <div
                          key={index}
                          className="flex items-center justify-between mt-1"
                        >
                          <span className="text-sm">{file.name}</span>
                          <button
                            type="button"
                            onClick={() => handleRemoveFile(index)}
                            className="text-red-500 text-sm"
                          >
                            Remove
                          </button>
                        </div>
                      ))}
                    </div>
                    {formErrors.idCopy && (
                      <ValidationError>{formErrors.idCopy}</ValidationError>
                    )}
                  </div>
                )}
                {guestType === "local" && (
                  <div className="mb-4">
                    <label
                      htmlFor="cidNumber"
                      className="block mb-2 text-xl font-bold font-garamond"
                    >
                      CID Number
                    </label>
                    <input
                      id="cidNumber"
                      type="text"
                      className={`w-full px-4 py-2 border font-montserrat ${
                        formErrors.cidNumber ? "border-red-500" : ""
                      }`}
                      placeholder="Enter your 11-digit CID number"
                      value={formDataf.cidNumber}
                      onChange={handleChange}
                    />
                    {formErrors.cidNumber && (
                      <ValidationError>{formErrors.cidNumber}</ValidationError>
                    )}
                  </div>
                )}

                <div className="mb-4">
                  <label
                    htmlFor="paymentOption"
                    className="block mb-2 text-xl font-bold font-garamond"
                  >
                    Payment Type
                  </label>
                  <div className="flex flex-wrap">
                    <label className="mr-4 mb-2">
                      <input
                        type="radio"
                        id="fullPayment"
                        name="paymentOption"
                        value="full"
                        checked={paymentOption === "full"}
                        onChange={(e) => setPaymentType(e.target.value)}
                        className="mr-1"
                      />
                      Full Payment
                    </label>
                    <label className="mb-2">
                      <input
                        type="radio"
                        id="halfPayment"
                        name="paymentOption"
                        value="half"
                        checked={paymentOption === "half"}
                        onChange={(e) => setPaymentType(e.target.value)}
                        className="mr-1"
                      />
                      Half Payment
                    </label>
                  </div>
                </div>

                <div className="mt-4">
                  <h6 className="text-xl font-bold font-garamond mb-2">
                    Price List
                  </h6>
                  <ul className="list-none">
                    {priceList.map(
                      ({ item, price }, index) => (
                        console.log(item),
                        (
                          <li
                            key={index}
                            className="mb-2 flex justify-between items-center font-montserrat"
                          >
                            <span>{item}:</span> <span>BTN.{price}</span>
                          </li>
                        )
                      )
                    )}
                  </ul>
                  <div className="mt-4">
                    <h6 className="text-xl font-bold font-garamond">
                      Total Price: BTN {calculateTotalPrice(0)}
                    </h6>
                  </div>
                </div>

                <button
                  type="submit"
                  className="w-full bg-[#c19e68] py-2 text-white mb-6 mt-4 font-garamond flex items-center justify-center"
                  disabled={isLoading}
                >
                  {isLoading && (
                    <svg
                      className="animate-spin h-5 w-5 mr-3 text-white"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <circle
                        className="opacity-25"
                        cx="12"
                        cy="12"
                        r="10"
                        stroke="currentColor"
                        strokeWidth="4"
                      ></circle>
                      <path
                        className="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                      ></path>
                    </svg>
                  )}
                  {isLoading ? "Booking..." : "Confirm Booking"}
                </button>
              </form>
              <ToastContainer />
            </div>
          </div>
        </div>
      </div>
      <div>
        <form
          id="bfsForm"
          method="post"
          action="https://uatbfssecure.rma.org.bt/BFSSecure/makePayment"
        >
          <input type="hidden" id="bfs_msgType" name="bfs_msgType" />
          <input type="hidden" id="bfs_benfId" name="bfs_benfId" />
          <input type="hidden" id="bfs_benfTxnTime" name="bfs_benfTxnTime" />
          <input type="hidden" id="bfs_orderNo" name="bfs_orderNo" />
          <input type="hidden" id="bfs_benfBankCode" name="bfs_benfBankCode" />
          <input type="hidden" id="bfs_txnCurrency" name="bfs_txnCurrency" />
          <input type="hidden" id="bfs_txnAmount" name="bfs_txnAmount" />
          <input
            type="hidden"
            id="bfs_remitterEmail"
            name="bfs_remitterEmail"
          />
          <input type="hidden" id="bfs_paymentDesc" name="bfs_paymentDesc" />
          <input
            type="hidden"
            id="bfs_version"
            value="1.0"
            name="bfs_version"
          />
          <input type="hidden" id="bfs_checkSum" name="bfs_checkSum" />
        </form>
      </div>
    </LockZoomContainer>
  );
};

export default RoomDetails;
