import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Tooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";
import { useLazyGetAvailableRoomsQuery } from "../pages/booking/apiSlice";
import LoadingAnimation from "./LoadingAnimation"; // Adjust the import path as needed

const ValidationPopup = ({ messages, onClose }) => {
  if (!messages.length) return null;

  return (
    <motion.div
      initial={{ opacity: 0, y: 50 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
      className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50 z-50"
    >
      <div className="bg-white p-5 rounded shadow-md text-center">
        <h2 className="text-xl font-bold mb-4">Validation Errors</h2>
        <ul className="list-none pl-0 pb-3">
          {messages.map((msg, index) => (
            <li key={index} className="text-red-500">
              {msg}
            </li>
          ))}
        </ul>
        <button
          onClick={onClose}
          className="bg-[#c19e68] text-black px-6 py-2 text-bg font-cormorant-garamond"
        >
          Close
        </button>
      </div>
    </motion.div>
  );
};
const BookingForm = () => {
  const navigate = useNavigate();
  const [checkIn, setCheckIn] = useState(null);
  const [checkOut, setCheckOut] = useState(null);
  const [guestType, setGuestType] = useState("local");
  const [adults, setAdults] = useState(1);
  const [children, setChildren] = useState(0);
  const [errors, setErrors] = useState([]);
  const [showPopup, setShowPopup] = useState(false);
  const [bookings, setBookings] = useState([]);
  // const [isLoading, setIsLoading] = useState(false);
  const [trigger, { data: rooms, error, isLoading }] =
    useLazyGetAvailableRoomsQuery();
  useEffect(() => {
    // Load bookings from localStorage
    const savedBookings = JSON.parse(localStorage.getItem("bookings")) || [];
    setBookings(savedBookings);
  }, []);
  // useEffect(() => {
  //   console.log(rooms);
  //   localStorage.setItem("rooms", JSON.stringify(rooms));
  // }, [rooms]);
  const isDateBooked = (date) => {
    return bookings.some((booking) => {
      const bookingStart = new Date(booking.checkIn);
      const bookingEnd = new Date(booking.checkOut);
      return date >= bookingStart && date <= bookingEnd;
    });
  };
  const getRoomInfoForDate = (date) => {
    const bookingsForDate = bookings.filter((booking) => {
      const bookingStart = new Date(booking.checkIn);
      const bookingEnd = new Date(booking.checkOut);
      return date >= bookingStart && date <= bookingEnd;
    });

    if (bookingsForDate.length === 0) return null;

    const roomInfo = bookingsForDate.reduce((acc, booking) => {
      if (!acc[booking.roomName]) {
        acc[booking.roomName] = { booked: 0, left: booking.totalRooms };
      }
      acc[booking.roomName].booked += 1;
      acc[booking.roomName].left -= 1;
      return acc;
    }, {});

    return roomInfo;
  };

  const renderDayContents = (day, date) => {
    const isBooked = isDateBooked(date);
    const roomInfo = getRoomInfoForDate(date);
    const tooltipId = `tooltip-${date.toISOString()}`;

    return (
      <div
        className={isBooked ? "text-red-500 font-bold" : ""}
        data-tooltip-id={tooltipId}
      >
        {day}
        {roomInfo && (
          <Tooltip id={tooltipId}>
            {Object.entries(roomInfo).map(([roomName, info]) => (
              <div key={roomName}>
                {roomName}: {info.booked} booked, {info.left} left
              </div>
            ))}
          </Tooltip>
        )}
      </div>
    );
  };

  const handleBookNow = async (e) => {
    e.preventDefault();
    const validationErrors = [];

    if (!checkIn) {
      validationErrors.push("Please select a check-in date");
    }

    if (!checkOut) {
      validationErrors.push("Please select a check-out date");
    }

    if (checkIn && checkOut && checkIn > checkOut) {
      validationErrors.push("Check-out date must be after check-in date");
    }

    if (validationErrors.length > 0) {
      setErrors(validationErrors);
      setShowPopup(true);
    } else {
      // setIsLoading(true); // Start loading
      // Simulate an API call or some processing
      // setIsLoading(false); // Stop loading
      const queryParams = { checkIn, checkOut };
      const result = await trigger(queryParams).unwrap();
      if (error) {
        console.log("error", error);
        return;
      }
      console.log(result);
      if (result.length < 0) {
        alert(result.length);
        alert("No room available");
        return;
      }

      localStorage.setItem("rooms", JSON.stringify(result));
      console.log(error);
      const timeZoneOffset = new Date().getTimezoneOffset() * 60000;
      const adjustedDateCheckIn = new Date(checkIn.getTime() - timeZoneOffset);
      const localDateISOCheckIn = adjustedDateCheckIn
        .toISOString()
        .split("T")[0];
      const adjustedDateCheckOut = new Date(
        checkOut.getTime() - timeZoneOffset
      );
      const localDateISOCheckOut = adjustedDateCheckOut
        .toISOString()
        .split("T")[0];
      navigate("/roomtype", {
        state: {
          checkIn: localDateISOCheckIn,
          checkOut: localDateISOCheckOut,
          guestType,
          adults,
          children,
        },
      });
    }
  };

  const handleGuestTypeChange = (e) => {
    setGuestType(e.target.value);
  };

  const handleAdultsChange = (e) => {
    setAdults(parseInt(e.target.value));
  };

  const handleChildrenChange = (e) => {
    setChildren(parseInt(e.target.value));
  };

  if (isLoading) {
    return <LoadingAnimation />;
  }

  return (
    <>
      <motion.div
        initial={{ opacity: 0, y: 50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
        className="flex justify-center items-center min-h-screen py-10 px-4 pt-40 sm:pt-16"
      >
        <div className="max-w-6xl w-full border-t-4 border-[#c19e68] p-6 bg-white text-black">
          <form
            className="flex flex-wrap items-center justify-between w-full space-y-4 md:space-y-0 px-5"
            onSubmit={handleBookNow}
          >
            <div className="flex flex-col items-start w-full md:w-auto relative md:mr-4">
              <label
                htmlFor="checkin"
                className="text-bg text-[#a3a29b] font-montserrat"
              >
                Check In
              </label>
              <DatePicker
                id="checkin"
                selected={checkIn}
                onChange={(date) => setCheckIn(date)}
                selectsStart
                startDate={checkIn}
                endDate={checkOut}
                minDate={new Date()}
                placeholderText="MM/DD/YYYY"
                className="focus:outline-none w-full uppercase text-bg font-garamond text-[#1e1e1e] appearance-none"
              />
            </div>
            <div className="w-full md:w-auto md:flex-shrink-0 border-t md:border-t-0 md:border-r border-gray-600 h-10"></div>
            <div className="flex flex-col items-start w-full md:w-auto relative md:mr-4">
              <label
                htmlFor="checkout"
                className="text-bg text-[#a3a29b] font-montserrat"
              >
                Check Out
              </label>
              <DatePicker
                id="checkout"
                selected={checkOut}
                onChange={(date) => setCheckOut(date)}
                selectsEnd
                startDate={checkIn}
                endDate={checkOut}
                minDate={checkIn}
                placeholderText="MM/DD/YYYY "
                className="focus:outline-none w-full uppercase text-bg font-garamond text-black appearance-none"
              />
            </div>
            <div className="w-full md:w-auto md:flex-shrink-0 border-t md:border-t-0 md:border-r border-gray-600 h-10"></div>
            <div className="flex flex-col items-start w-full md:w-auto relative md:mr-4">
  <label htmlFor="guestType" className="text-bg text-[#a3a29b] font-montserrat">
    Type of Guest
  </label>
  <div className="relative w-full">
    <select
      id="guestType"
      value={guestType}
      onChange={handleGuestTypeChange}
      className="focus:outline-none w-full uppercase text-bg font-garamond text-[#1e1e1e] appearance-none bg-transparent"
      style={{ border: "none", WebkitAppearance: "none", MozAppearance: "none" }}
    >
      <option value="local">Local</option>
      <option value="foreigner">Regional</option>
    </select>
    <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pl-15 text-gray-700">
      <svg
        className="fill-current h-4 w-4"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 20"
      >
        <path d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" />
      </svg>
    </div>
  </div>
</div>

<div className="w-full md:w-auto md:flex-shrink-0 border-t md:border-t-0 md:border-r border-gray-600 h-10"></div>

<div className="flex flex-col items-start w-full md:w-auto relative md:mr-4">
  <label htmlFor="children" className="text-bg text-[#a3a29b] font-montserrat">
    Children
  </label>
  <div className="relative w-full">
    <select
      id="children"
      value={children}
      onChange={handleChildrenChange}
      className="focus:outline-none w-full uppercase text-bg font-garamond text-[#1e1e1e] appearance-none bg-transparent"
      style={{ border: "none", WebkitAppearance: "none", MozAppearance: "none" }}
    >
      {[...Array(10).keys()].map((num) => (
        <option key={num} value={num}>
          {num}
        </option>
      ))}
    </select>
    <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center text-gray-700">
      <svg
        className="fill-current h-4 w-4"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 20"
      >
        <path d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" />
      </svg>
    </div>
  </div>
</div>

<div className="w-full md:w-auto md:flex-shrink-0 border-t md:border-t-0 md:border-r border-gray-600 h-10"></div>

<div className="flex flex-col items-start w-full md:w-auto relative md:mr-4">
  <label htmlFor="adults" className="text-bg text-[#a3a29b] font-montserrat">
    Adults
  </label>
  <div className="relative w-full">
    <select
      id="adults"
      value={adults}
      onChange={handleAdultsChange}
      className="focus:outline-none w-full uppercase text-bg font-garamond text-[#1e1e1e] appearance-none bg-transparent"
      style={{ border: "none", WebkitAppearance: "none", MozAppearance: "none" }}
    >
      {[...Array(10).keys()].map((num) => (
        <option key={num + 1} value={num + 1}>
          {num + 1}
        </option>
      ))}
    </select>
    <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center text-gray-700">
      <svg
        className="fill-current h-4 w-4"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 20"
      >
        <path d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" />
      </svg>
    </div>
  </div>
</div>

            <div className="flex flex-col items-center justify-center w-full md:w-auto">
              <button
                type="submit"
                className="bg-[#c19e68] text-black px-6 py-2 text-bg font-cormorant-garamond w-full md:w-auto"
              >
                Continue
              </button>
            </div>
          </form>
        </div>
        {showPopup && (
          <ValidationPopup
            messages={errors}
            onClose={() => setShowPopup(false)}
          />
        )}
      </motion.div>
    </>
  );
};

export default BookingForm;
