import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { motion } from "framer-motion";

const localRooms = [
  {
    name: "Single Bed Room",
    prices: {
      ep: 1800,
      cp: 2100,
      map: 2700,
      ap: 3500,
    },
    initialAvailability: 4,
    image: "/images/general/single_room.jpeg",
  },
  {
    name: "Twin Bed Room",
    prices: {
      ep: 2400,
      cp: 3000,
      map: 4200,
      ap: 5800,
    },
    initialAvailability: 4,
    image: "/images/general/twin_bed_room.jpeg",
    notice: "One guest only.",
  },
  {
    name: "Deluxe Bed Room",
    prices: {
      ep: 2700,
      cp: 3000,
      map: 3600,
      ap: 4400,
    },
    initialAvailability: 4,
    image: "/images/general/deluxe_room.jpg",
    notice: "Two guests only.",
  },
  {
    name: "Suite 1 Bed Room",
    prices: {
      ep: 3200,
      cp: 3500,
      map: 4100,
      ap: 4900,
    },
    initialAvailability: 1,
    image: "/images/general/Suite_1.jpeg",
  },
  {
    name: "Suite 2 Bed Room",
    prices: {
      ep: 3600,
      cp: 3900,
      map: 4500,
      ap: 5300,
    },
    initialAvailability: 1,
    image: "/images/general/Suite_2rt.jpeg",
  },
];

const internationalRooms = [
  {
    name: "Single Bed Room",
    prices: {
      ep: 2100,
      cp: 2450,
      map: 3100,
      ap: 4000,
    },
    initialAvailability: 4,
    image: "/images/general/single_room.jpeg",
  },
  {
    name: "Twin Bed Room",
    prices: {
      ep: 3400,
      cp: 4100,
      map: 5400,
      ap: 6850,
    },
    initialAvailability: 4,
    image: "/images/general/twin_bed_room.jpeg",
    notice: "One guest only.",
  },
  {
    name: "Deluxe Bed Room",
    prices: {
      ep: 2700,
      cp: 3050,
      map: 3700,
      ap: 4600,
    },
    initialAvailability: 4,
    image: "/images/general/deluxe_room.jpg",
  },
  {
    name: "Suite 1 Bed Room",
    prices: {
      ep: 3200,
      cp: 3550,
      map: 4200,
      ap: 5100,
    },
    initialAvailability: 1,
    image: "/images/general/Suite_1.jpeg",
  },
  {
    name: "Suite 2 Bed Room",
    prices: {
      ep: 3700,
      cp: 4050,
      map: 4700,
      ap: 5600,
    },
    initialAvailability: 1,
    image: "/images/general/Suite_2rt.jpeg",
  },
];

const RoomType = () => {
  const [rooms, setRooms] = useState(JSON.parse(localStorage.getItem("rooms")));

  const navigate = useNavigate();
  const { state } = useLocation();
  const { checkIn, checkOut, guestType, adults, children } = state || {};
  // const rooms = guestType === "foreigner" ? internationalRooms : localRooms;
  const [selectedPlans, setSelectedPlans] = useState(rooms.map(() => ""));
  const [availability, setAvailability] = useState(
    rooms.map((room) => room.initialAvailability)
  );
  // useEffect(() => {
  //   // Update availability based on selected plans and check-out dates
  //   const updateAvailability = () => {
  //     const newAvailability = rooms.map((room, index) => {
  //       if (selectedPlans[index] && checkOut) {
  //         // Simulate booking logic: decrease availability when a plan is selected
  //         return availability[index] - 1;
  //       } else if (checkOut) {
  //         // Increase availability after check-out date
  //         return room.initialAvailability;
  //       } else {
  //         // Initial availability
  //         return room.initialAvailability;
  //       }
  //     });
  //     setAvailability(newAvailability);
  //   };

  //   updateAvailability();
  // }, [selectedPlans, checkOut]);

  const handlePlanChange = (index, newPlan) => {
    console.log("index", index, "newPlan", newPlan);
    const newSelectedPlans = [...selectedPlans];
    console.log("newSelectedPlans", newSelectedPlans);
    newSelectedPlans[index] = newPlan;
    console.log("newSelectedPlans", newSelectedPlans);
    setSelectedPlans(newSelectedPlans);
  };

  const handleBookNow = (index) => {
    const selectedRoom = rooms[index];
    const tempId = rooms[index].tempId;
    if (selectedPlans[index]) {
      navigate(`/roomdetails/${index}`, {
        state: {
          room: { ...selectedRoom, selectedPlan: selectedPlans[index] },
          checkIn,
          checkOut,
          guestType,
          adults,
          tempId,
          children,
        },
      });
    } else {
      console.log("No plan selected for room:", selectedRoom.name);
    }
  };

  const planNotices = {
    ep: "Only Room",
    cp: "Room + Breakfast",
    map: "Room + Breakfast + any one meal",
    ap: "Room + three meals",
  };

  return (
    <motion.div
      initial={{ opacity: 0, y: 50 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
      style={{ backgroundColor: "#f8f6f3" }}
    >
      <div className="container mx-auto p-4 max-w-screen-lg mt-20">
        {checkIn && checkOut && guestType && (
          <div className="max-w-5xl w-full border-t-4 border-[#c19e68] p-6 bg-[#1e1e1e] text-white items-center mb-8">
            <form
              className="flex flex-wrap items-center justify-between w-full space-x-0 md:space-x-4 px-5"
              readOnly
            >
              <div className="grid grid-cols-2 gap-4 w-full md:flex md:flex-row md:w-auto md:space-x-4">
                <div className="flex flex-col items-start relative w-full md:w-auto mb-4 md:mb-0">
                  <label
                    htmlFor="checkin"
                    className="text-bg font-montserrat text-[#a3a29b]"
                  >
                    Check In
                  </label>
                  <div className="relative w-full">
                    <input
                      type="date"
                      id="checkin"
                      value={checkIn}
                      readOnly
                      className="focus:outline-none w-full uppercase text-bg font-garamond text-white bg-[#1e1e1e] appearance-none"
                      style={{ border: "none" }}
                    />
                  </div>
                </div>
                <div className="border-r border-gray-600 h-10 hidden md:block"></div>
                <div className="flex flex-col items-start relative w-full md:w-auto mb-4 md:mb-0">
                  <label
                    htmlFor="checkout"
                    className="text-bg font-montserrat text-[#a3a29b]"
                  >
                    Check Out
                  </label>
                  <div className="relative w-full">
                    <input
                      type="date"
                      id="checkout"
                      value={checkOut}
                      readOnly
                      className="focus:outline-none w-full uppercase text-bg font-garamond text-white bg-[#1e1e1e] appearance-none"
                      style={{ border: "none" }}
                    />
                  </div>
                </div>
                <div className="border-r border-gray-600 h-10 hidden md:block"></div>
                <div className="flex flex-col items-start relative w-full md:w-auto mb-4 md:mb-0">
                  <label
                    htmlFor="guestType"
                    className="text-bg font-montserrat text-[#a3a29b]"
                  >
                    Guest Type
                  </label>
                  <div className="relative w-full">
                    <select
                      id="guestType"
                      value={guestType}
                      disabled
                      className="focus:outline-none w-full uppercase text-bg font-garamond text-white bg-[#1e1e1e] appearance-none disabled:opacity-100 disabled:text-white"
                      style={{ border: "none" }}
                    >
                      <option value="Local Guest">Local Guest</option>
                      <option value="International Guest">
                        International Guest
                      </option>
                    </select>
                  </div>
                </div>
                <div className="border-r border-gray-600 h-10 hidden md:block"></div>
                <div className="flex flex-col items-start relative w-full md:w-auto mb-4 md:mb-0">
                  <label
                    htmlFor="children"
                    className="text-bg text-[#a3a29b] font-montserrat"
                  >
                    Children
                  </label>
                  <div className="relative w-full">
                    <select
                      id="children"
                      value={children}
                      disabled
                      className="focus:outline-none w-full uppercase text-bg font-garamond text-white bg-[#1e1e1e] md:text-left appearance-none disabled:opacity-100 disabled:text-white"
                      style={{ border: "none" }}
                    >
                      {[...Array(10).keys()].map((num) => (
                        <option key={num} value={num}>
                          {num}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="border-r border-gray-600 h-10 hidden md:block"></div>
                <div className="flex flex-col items-start relative w-full md:w-auto mb-4 md:mb-0">
                  <label
                    htmlFor="adults"
                    className="text-bg text-[#a3a29b] font-montserrat"
                  >
                    Adults
                  </label>
                  <div className="relative w-full">
                    <select
                      id="adults"
                      value={adults}
                      disabled
                      className="focus:outline-none w-full uppercase text-bg font-garamond text-white bg-[#1e1e1e] md:text-left appearance-none disabled:opacity-100 disabled:text-white"
                      style={{ border: "none" }}
                    >
                      {[...Array(10).keys()].map((num) => (
                        <option key={num + 1} value={num + 1}>
                          {num + 1}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="border-r border-gray-600 h-10 hidden md:block"></div>
                <div className="flex flex-col items-center justify-center w-full md:w-auto mb-4 md:mb-0">
                  <button
                    className="bg-[#c19e68] text-white px-6 py-2 text-bg font-garamond w-full md:w-auto"
                    onClick={() => navigate("/booking")}
                  >
                    Back
                  </button>
                </div>
              </div>
            </form>
          </div>
        )}
        {rooms.map((room, index) => (
          <motion.div
            key={index}
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: index * 0.1 }}
            className="flex flex-col sm:flex-row border shadow-lg mb-4 overflow-hidden relative"
            style={{ minHeight: "200px" }}
          >
            <div className="w-full sm:w-1/3 flex-shrink-0">
              <motion.img
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.5 }}
                className="w-full h-full object-cover"
                src={room.image}
                alt={room.roomType}
              />
            </div>
            <motion.div
              initial={{ opacity: 0, x: -50 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.5, delay: index * 0.1 + 0.2 }}
              className="w-full sm:w-2/3 flex flex-col sm:flex-row font-montserrat"
            >
              <div className="w-full sm:w-1/2 pl-4 pr-4 pt-4 flex flex-col justify-between">
                <div>
                  <h2 className="text-2xl mb-4 font-garamond uppercase">
                    {room.roomType}
                  </h2>
                  {room.notice && (
                    <p className="text-red-600 font-medium text-xx mb-4">
                      {room.notice}
                    </p>
                  )}
                  <div className="relative mb-2 sm:mb-0">
                    <select
                      className="w-full focus:outline-none appearance-none bg-transparent text-xx"
                      value={selectedPlans[index]}
                      onChange={(e) => handlePlanChange(index, e.target.value)}
                    >
                      <option value="" disabled>
                        Select Plan
                      </option>
                      <option value="ep">European Plan</option>
                      <option value="cp">Continental Plan</option>
                      <option value="map">Modified American Plan</option>
                      <option value="ap">American Plan</option>
                    </select>
                    <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center text-gray-700">
                      <svg
                        className="fill-current h-4 w-4"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                      >
                        <path d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" />
                      </svg>
                    </div>
                  </div>
                  {selectedPlans[index] && (
                    <div className="bg-[#f5f5f5] text-black w-full pt-3">
                      <p>{planNotices[selectedPlans[index]]}</p>
                    </div>
                  )}
                </div>
              </div>
              <div className="border-l border-gray-400 hidden sm:block"></div>
              <motion.div
                initial={{ opacity: 0, x: 50 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ duration: 0.5, delay: index * 0.1 + 0.2 }}
                className="w-full sm:w-1/2 pl-3 flex flex-col items-end pr-4 pt-2 sm:pt-11 text-xx"
              >
                <div className="flex flex-col items-end mb-auto pt-2 sm:pt-4">
                  <p className="font-medium mb-2 sm:mb-3">
                    Price:
                    {selectedPlans[index]
                      ? `Nu.${room[selectedPlans[index]][guestType]} / night`
                      : "Select a plan / night"}
                  </p>
                  <p className="text-red-600 font-medium mb-2 sm:mb-4">{`Only ${availability[index]} left`}</p>
                </div>
                <motion.button
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                  className={`bg-[#c19e68] text-white px-4 py-2 mb-4 ${
                    !selectedPlans[index] ? "opacity-50 cursor-not-allowed" : ""
                  }`}
                  onClick={() => handleBookNow(index)}
                  disabled={!selectedPlans[index] || availability[index] === 0}
                >
                  Select Room
                </motion.button>
              </motion.div>
            </motion.div>
          </motion.div>
        ))}
      </div>
    </motion.div>
  );
};

export default RoomType;
